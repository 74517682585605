import { useAtomValue } from "jotai";
import { localeAtom } from "utils/locale/state";

function TeamMember({ member }) {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  return (
    <div className="team-member">
      <div className="team-member-wrapper">
        <div className="member-header">
          <img src={member?.avatar?.at(0)?.url} alt="team-member" />
        </div>
        <div className="member-body">
          <h4 className={isArm ? "font-kirk" : ""}>{member[`name_${language}`]}</h4>
          <p>{member[`position_${language}`]}</p>
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
