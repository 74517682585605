import "./App.css";
import DonateModal from "components/Donate/DonateModal";
import { donateModalAtom } from "components/Donate/state";
import Footer from "components/Layout/Footer";
import Header from "components/Layout/Header";
import News from "components/News";
import Partners from "components/Partners";
import PrivacyPolicy from "components/Privacy";
import Projects from "components/Programs/index.jsx";
import StatusPage from "components/StatusPage";
import { useAtomValue } from "jotai";
import AboutUs from "pages/about-us";
import Home from "pages/home";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();

  const isModalOpen = useAtomValue(donateModalAtom);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/news/:id" element={<News />} />
        <Route path="/projects/:id" element={<Projects />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/paymentStatus" element={<StatusPage />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
      <DonateModal show={isModalOpen} />
    </div>
  );
}

export default App;
