import DynamicHelmet from "../Helmet/DynamicHelmet";
import Error from "../Reusable/Error";
import SinglePostWrapper from "../Reusable/SinglePostWrapper";
import SpinnerLoader from "../Reusable/Spinner";
import ArticleCard from "../UI/Cards/ArticleCard";
import CardContainer from "../UI/Layout/CardContainer";
import { projectContentText } from "./content";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import { getProjects } from "api/services";
import { useAtomValue } from "jotai";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { localeAtom } from "utils/locale/state";

function Projects() {
  const language = useAtomValue(localeAtom);

  const { archivedText } = projectContentText[language];

  const { id } = useParams();

  const params = {
    currentId: id,
    limit: 8
  };

  // useQuery
  const { data, isPending, isError } = useQuery({
    queryKey: ["projects", id],
    queryFn: async () => getProjects({ params })
  });

  if (isPending) {
    return <SpinnerLoader />;
  }

  if (isError) {
    return <Error />;
  }

  const { projects, currentProject, category, archived } = data;

  return (
    <div>
      <DynamicHelmet title={"HOH | Projects"} />
      <SinglePostWrapper data={currentProject} isLoading={isPending} category={category} />
      <Container>
        <CardContainer>
          {projects?.map((post) => (
            <ArticleCard post={post} key={post._id} category={category} />
          ))}
        </CardContainer>
        {!!archived?.length && (
          <div className="rounded-sm bg-yellow-400 p-2">
            <div className="font-light uppercase tracking-wider text-prmDark">{archivedText}</div>
            <CardContainer>
              {archived?.map((post) => (
                <ArticleCard post={post} key={post._id} category={category} />
              ))}
            </CardContainer>
          </div>
        )}
      </Container>
    </div>
  );
}

export default Projects;
