import { transformedImageUrl } from "../../utils";
import DynamicHelmet from "../Helmet/DynamicHelmet";
import Error from "../Reusable/Error";
import Loader from "../Reusable/Loader";
import NewPartner from "./NewPartner/NewPartner";
import { partnerContent } from "./content.js";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import { getPartners } from "api/services";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { localeAtom } from "utils/locale/state";

function Partners() {
  const language = useAtomValue(localeAtom);
  const { mainTitleText, mainParagraphFooterText, mainParagraphText } = partnerContent[language];

  const [isMore, setIsMore] = useState(false);

  const { data, isPending, isError } = useQuery({
    queryKey: ["partners"],
    queryFn: async () => await getPartners()
  });

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Container fluid className="partners">
      <DynamicHelmet title={"HOH | Partners"} />
      <Container>
        <div className="partners-main-wrapper">
          <Col md={12} lg={6} className="partners-header">
            <h2>{mainTitleText}</h2>
            <p>{mainParagraphText}</p>
            <p>{mainParagraphFooterText}</p>
            <div className="mobile-button-header">
              <button onClick={() => setIsMore(!isMore)}>
                {isMore ? "Show less..." : "Show More..."}
              </button>
            </div>
          </Col>
          <Col md={12} lg={5} className="image-wrapper">
            <img
              src={transformedImageUrl({
                url: "https://res.cloudinary.com/di8iypeft/image/upload/v1698090152/HouseOfHope/statics/photo-three_tgzvrn.jpg",
                width: 1000
              })}
              alt="partners-cover"
            />
          </Col>
        </div>

        <Row className="partners-body">
          {data?.map((partner) => (
            <NewPartner key={partner._id} partner={partner} language={language} />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Partners;
