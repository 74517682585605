import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { htmlToText } from "html-to-text";
import { useAtomValue } from "jotai";
import { classNames, transformedImageUrl } from "utils";
import { localeAtom } from "utils/locale/state";

const ProjectCard = ({ post, currentIndex }) => {
  const locale = useAtomValue(localeAtom);

  const isArm = locale === "am";
  const colors = ["#F74F21", "#0033A0", "#FFAB00", "#FF6E18", "#4D77D1", "#FFC225"];

  const cardImageUrl = transformedImageUrl({
    url: post?.cover?.url ?? post?.pictures?.at(0).url,
    ratio: "9:11",
    width: 1000
  });
  const title = post[`title_${locale}`];
  const description = htmlToText(post[`description_${locale}`]);

  return (
    <div
      className={classNames(
        "relative aspect-[12/9] lg:aspect-[9/11]",
        "before:absolute before:inset-0 before:z-10 before:rounded-xl before:bg-[#14151fbb] before:bg-opacity-50 before:backdrop-blur-[1px]"
      )}
    >
      <img
        className={classNames(
          "absolute inset-0 h-full w-full rounded-lg border object-cover grayscale"
        )}
        src={cardImageUrl}
        alt={post?.cover?.public_id ?? post.pictures.at(0).public_id}
      />
      <div className="flex flex-col gap-2 p-4">
        <h4
          className={classNames(
            "relative z-20 mt-2 uppercase text-white",
            isArm ? " font-kirk" : " font-geist font-semibold"
          )}
        >
          {title}
        </h4>
        <p className="relative z-20 line-clamp-3 font-geist text-sm font-light text-white">
          {description}
        </p>
      </div>
      <div
        style={{ backgroundColor: colors[currentIndex] }}
        className={classNames(
          "absolute bottom-5 left-0 z-20 cursor-pointer rounded-r-full py-1 pl-4 pr-1 md:bottom-8"
        )}
      >
        <a
          href={`/projects/${post._id}`}
          className="group flex items-center justify-center gap-2 rounded-full bg-white p-1 px-3 lg:h-10"
        >
          <div className={"h-7 w-7 whitespace-nowrap uppercase transition-all duration-200"}>
            <ChevronDoubleRightIcon style={{ color: colors[currentIndex] }} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
