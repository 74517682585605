import { offsetAtom } from "../../Reusable/Paginate/state";
import SpinnerLoader from "../../Reusable/Spinner";
import ArticleCard from "../../UI/Cards/ArticleCard";
import CardContainer from "../../UI/Layout/CardContainer";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import { getArticles } from "api/services";
import Error from "components/Reusable/Error";
import { useAtomValue } from "jotai";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";

function NewsHome() {
  const offset = useAtomValue(offsetAtom);

  const params = {
    limit: 4,
    sort: "name,-date",
    offset,
    homeData: true
  };

  const { data, isError, isPending } = useQuery({
    queryKey: ["articles"],
    queryFn: async () => getArticles({ params })
  });

  if (isPending) {
    return <SpinnerLoader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <ContainerWrapper>
      <ContainerTitle title={"news"} navTo={"news"} />
      <CardContainer>
        {data?.articles?.map((post) => (
          <ArticleCard post={post} key={post._id} category={data.category} />
        ))}
      </CardContainer>
    </ContainerWrapper>
  );
}

export default NewsHome;
