import { useQuery } from "@tanstack/react-query";
import { getTeamMembers } from "api/services";
import Story from "components/AboutUs/Story";
import Team from "components/AboutUs/Team";
import "components/AboutUs/style.css";
import BoardOfTrusties from "components/AboutUs/trusties";
import DynamicHelmet from "components/Helmet/DynamicHelmet";
import PageWrapper from "ui/wrappers/page-wrapper";

function AboutUs() {
  const { data, isError, isPending } = useQuery({
    queryKey: ["teams"],
    queryFn: async () => getTeamMembers()
  });

  return (
    <PageWrapper>
      <DynamicHelmet title={"HOH | About Us"} />
      <Story />
      <BoardOfTrusties data={data} isError={isError} isPending={isPending} />
      <Team data={data} isError={isError} isPending={isPending} />
    </PageWrapper>
  );
}

export default AboutUs;
