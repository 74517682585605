import TeamMember from "./Team/TeamMember";
import { aboutUsContent } from "./data";
import { groupCategories } from "./utils";
import Error from "components/Reusable/Error";
import Loader from "components/Reusable/Loader";
import { useAtomValue } from "jotai";
import { Fragment } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperCarousel from "ui/swipers/swiper-carousel";
import ContainerWrapper from "ui/wrappers/container-wrapper";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const BoardOfTrusties = ({ data, isError, isPending }) => {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  if (isError) {
    return <Error />;
  }

  if (isPending) {
    return <Loader />;
  }

  const groupedTeams = groupCategories(data);

  const trustiesData = groupedTeams["boardMember"];

  const {
    team: { trustees }
  } = aboutUsContent[language];

  if (!trustiesData) {
    return null;
  }

  return (
    <div className="our-team-wrapper w-full">
      <ContainerWrapper>
        <div className={classNames("our-team-header mb-5 uppercase", isArm ? "font-kirk" : "")}>
          <h2>{trustees}</h2>
        </div>
        <SwiperCarousel>
          {groupedTeams["boardMember"]
            ?.sort((a, b) => b.sequence - a.sequence)
            .map((member, i) => {
              return (
                <Fragment key={`${i}`}>
                  <div className="team-container">
                    <SwiperSlide key={member._id}>
                      <TeamMember member={member} />
                    </SwiperSlide>
                  </div>
                </Fragment>
              );
            })}
        </SwiperCarousel>
      </ContainerWrapper>
    </div>
  );
};

export default BoardOfTrusties;
