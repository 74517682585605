import { cardContent } from "./card-content";
import CardContainer from "components/UI/Layout/CardContainer";
import VolunteerCard from "ui/cards/volunteer-card";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";

function Volunteer() {
  const content = cardContent;

  return (
    <ContainerWrapper>
      <ContainerTitle title={"volunteers"} />
      <CardContainer>
        {content.map((cardData, index) => (
          <VolunteerCard data={cardData} key={index} />
        ))}
      </CardContainer>
    </ContainerWrapper>
  );
}

export default Volunteer;
