import SwiperCarousel from "../../../ui/swipers/swiper-carousel";
import ContainerWrapper from "../../../ui/wrappers/container-wrapper";
import { classNames } from "../../../utils";
import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import { mapCategory } from "../data";
import { groupCategories } from "../utils";
import TeamMember from "./TeamMember";
import { useAtomValue } from "jotai";
import { Fragment } from "react";
import { SwiperSlide } from "swiper/react";
import { localeAtom } from "utils/locale/state";

function Team({ data, isError, isPending }) {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  if (isError) {
    return <Error />;
  }

  if (isPending) {
    return <Loader />;
  }

  const groupedTeams = groupCategories(data);
  return (
    <ContainerWrapper>
      <div className="our-team-wrapper w-full">
        <div className={classNames("our-team-header mb-5 uppercase", isArm ? "font-kirk" : "")}>
          {/* <h2>{teamTitleText}</h2> */}
        </div>
        {Object.keys(groupedTeams)
          .sort((a, b) => {
            if (a === "management") return -1;
            if (b === "management") return 1;
            return 0;
          })
          .map((category, i) => {
            if (category === "boardMember") {
              return null;
            }

            return (
              <Fragment key={`${category}-${i}`}>
                <div className="our-team-header mb-4 mt-5 uppercase">
                  <h4 className={isArm ? "font-kirk" : ""}>{mapCategory(category, language)}</h4>
                </div>
                <div className="team-container">
                  <SwiperCarousel>
                    {groupedTeams[category]
                      .sort((a, b) => b.sequence - a.sequence)
                      .map((member) => (
                        <SwiperSlide key={member._id}>
                          <TeamMember member={member} />
                        </SwiperSlide>
                      ))}
                  </SwiperCarousel>
                </div>
              </Fragment>
            );
          })}
      </div>
    </ContainerWrapper>
  );
}

export default Team;
