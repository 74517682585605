import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import CardContainer from "../../UI/Layout/CardContainer";
import { useQuery } from "@tanstack/react-query";
import { getProjects } from "api/services";
import { Fragment } from "react";
import ProjectCard from "ui/cards/project-card";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";

function ProjectHome() {
  const params = {
    limit: 4,
    noFilter: true,
    homeData: true
  };

  const { data, isError, isPending } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => getProjects({ params })
  });

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <ContainerWrapper>
      <ContainerTitle title={"projects"} navTo={"/projects"} />
      <CardContainer>
        {data?.projects?.map((post, index) => {
          if (!post) return null;

          return (
            <Fragment key={post._id}>
              <ProjectCard post={post} currentIndex={index} />
            </Fragment>
          );
        })}
      </CardContainer>
    </ContainerWrapper>
  );
}

export default ProjectHome;
