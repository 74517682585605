import { axiosInstance } from "../axios/axiosInstance";

export const getTeamMembers = async (id) => {
  const url = id ? `teams/${id}` : "teams";
  return (await axiosInstance.get(url)).data;
};

export const getArticles = async ({ id, params }) => {
  const url = id ? `articles/${id}` : "articles";
  return (await axiosInstance.get(url, { params })).data;
};

export const getProjects = async ({ id, params }) => {
  const url = id ? `projects/${id}` : "projects";
  return (await axiosInstance.get(url, { params })).data;
};

export const getPartners = async (id) => {
  const url = id ? `partners/${id}` : "partners";
  return (await axiosInstance.get(url)).data;
};
