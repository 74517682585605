import { htmlToText } from "html-to-text";
import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames, transformedImageUrl } from "utils";
import { dateExtractor } from "utils/dates";
import { localeAtom } from "utils/locale/state";

export default function ArticleCard({ post = {}, category = "" }) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isHomePage = pathname === "/";
  const isArticle = category === "articles";

  //Global States
  const locale = useAtomValue(localeAtom);

  const handleArticleChange = useCallback(
    () => {
      const currentCategory = isArticle ? "news" : category;

      if (!isHomePage) {
        navigate(`/${currentCategory}/${post._id}/`);
        window.scrollTo(0, 0);
      } else {
        navigate(`/${currentCategory}/${post._id}/`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, post]
  );

  const article = useMemo(() => {
    return {
      ...post,
      imageUrl: post?.pictures[0]?.url || post?.cover?.url,
      date: dateExtractor(post.date || post.createdAt, locale),
      title: post[`title_${locale}`],
      paragraph: htmlToText(post[`description_${locale}`])
    };
  }, [post, locale]);

  const cardImageUrl = transformedImageUrl({ url: article.imageUrl, ratio: "16:9", width: 600 });

  return (
    <article className="relative flex flex-col items-start justify-start">
      <img
        src={cardImageUrl}
        alt={`charity-armenia-${post._id}`}
        className="aspect-[5/3] w-full rounded-lg object-cover "
      />
      <div className="flex max-w-xl flex-col justify-between gap-1">
        {isArticle && (
          <div className="mt-2 flex items-center text-sm">
            <time className="font-num text-base font-semibold uppercase tracking-wider text-gray-500">
              {article.date}
            </time>
          </div>
        )}
        <h3
          className={classNames(
            "line-clamp-2 cursor-pointer overflow-hidden text-ellipsis text-base font-bold !leading-5 text-prmDark hover:text-prmOrange"
          )}
          onClick={handleArticleChange}
        >
          {article.title}
        </h3>
        <p className="line-clamp-2 font-geist text-sm font-light text-prmDark md:text-sm">
          {article.paragraph}
        </p>
      </div>
    </article>
  );
}
