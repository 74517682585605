import DynamicHelmet from "../Helmet/DynamicHelmet";
import Paginate from "../Reusable/Paginate/index";
import { offsetAtom } from "../Reusable/Paginate/state";
import SinglePostWrapper from "../Reusable/SinglePostWrapper/index";
import SpinnerLoader from "../Reusable/Spinner";
import ArticleCard from "../UI/Cards/ArticleCard";
import CardContainer from "../UI/Layout/CardContainer";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import { getArticles } from "api/services";
import Error from "components/Reusable/Error";
import { useAtomValue } from "jotai";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

function News() {
  const { id } = useParams();
  const offset = useAtomValue(offsetAtom);

  const params = {
    limit: 8,
    sort: "name,-date",
    offset,
    currentArticleId: id
  };

  const { data, isPending, isError } = useQuery({
    queryKey: ["articles", id, offset],
    queryFn: async () => getArticles({ params })
  });

  if (isPending) {
    return <SpinnerLoader />;
  }

  if (isError) {
    return <Error />;
  }

  const { articles, currentArticle, category } = data;

  return (
    <div>
      <DynamicHelmet title={"HOH | News"} />
      <SinglePostWrapper data={currentArticle} category={category} isLoading={isPending} />
      <Container>
        <CardContainer>
          {articles.map((post) => (
            <ArticleCard post={post} key={post._id} category={category} />
          ))}
        </CardContainer>
      </Container>
      <Paginate articles={data} />
    </div>
  );
}

export default News;
